import Navigation from './Navigation';
import './App.css';

function App() {
  return <Navigation/>
}

export default App;


/**
 * Notes:
 *      - WAVE tool for acessability
 * 
 * TODO: 
 *      - copyright statement: "redirect to VKLLC.com"
 *      - navbar
 *        - dropdown menu styling
 */